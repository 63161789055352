@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local('Lato Hairline'), local('Lato-Hairline'),
    url(../fonts/lato/v6/Kom15zUm24dIPfIRiNogNuvvDin1pK8aKteLpeZ5c0A.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'),
    url(../fonts/lato/v6/kcf5uOXucLcbFOydGU24WALUuEpTyoUstqEm5AMlJo4.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'),
    url(../fonts/lato/v6/qIIYRU-oROkIk8vfvxw6QvesZW2xOQ-xsNqO47m55DA.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'),
    url(../fonts/lato/v6/qdgUG4U09HnJwhYI-uK18wLUuEpTyoUstqEm5AMlJo4.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local('Lato Black'), local('Lato-Black'),
    url(../fonts/lato/v6/G2uphNnNqGFMHLRsO_72ngLUuEpTyoUstqEm5AMlJo4.woff) format('woff');
}

// All fonts should be Lato light to start
body {
  font-weight: normal;

  * {
    margin-top: 0;
  }
}

a {
  text-decoration: underline;
}

.container-fluid {
  position: relative;
}

.lead {
  font-weight: normal;
}

.small {
  font-size: 14px;
}

.xsmall {
  font-size: 13px;
}

.subtext {
  font-weight: normal;
  line-height: 1.75em;
}

// Headings
h1 {
  font-size: 34px;
  font-weight: normal;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h1,
h2,
h3 {
  small,
  .small {
    font-weight: normal;
  }
}

// Set some basic styles for content.
p {
  font-size: 18px;
}

p.empty-state {
  font-size: 22px;
}
